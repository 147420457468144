import { createEffect, JSX, Show } from "solid-js"
import { createSignal } from "solid-js"
import { Button } from "./ui/button"

import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip"
import { Input } from "./ui/input"
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronDown,
  ChevronRight,
  DeleteIcon,
  ReplaceAllIcon,
  ReplaceIcon,
  Trash,
  Trash2Icon,
  X,
} from "lucide-solid"
import { Word } from "../lib/bdb"

export function createSearchSignal() {
  const [isExpanded, setIsExpanded] = createSignal(false)

  const [searchText, setSearchText] = createSignal("")
  const [replaceText, setReplaceText] = createSignal("")
  /**  是否显示 */
  const [showReplace, setShowReplace] = createSignal(false)
  /* index 从1开始计数  */
  const [reasultIndex, setReasultIndex] = createSignal(0)
  const [resultCount, setResultCount] = createSignal(0)

  const [searchMatchIndexes, setSearchMatchIndexes] = createSignal<number[]>([])
  const [words, setWords] = createSignal<Word[]>([])

  return {
    isExpanded,
    setIsExpanded,
    searchText,
    setSearchText,
    replaceText,
    setReplaceText,
    /* index 从1开始计数  */
    reasultIndex,
    setReasultIndex,
    resultCount,
    setResultCount,
    searchMatchIndexes,
    setSearchMatchIndexes,
    words,
    setWords,
    showReplace,
    setShowReplace,
  }
}

export default function SearchBox(props: {
  signal: ReturnType<typeof createSearchSignal>
  onReplaceText?: (
    text: string,
    replaceText: string,
    indexInWords: number,
  ) => void
  onReplaceAllText?: (text: string, replaceText: string) => void
  onDeleteSelected?: (indexInWords: number) => void
  onBatchDelete?: (indexes: number[]) => void
}): JSX.Element {
  let {
    isExpanded,
    setIsExpanded,
    searchText,
    setSearchText,
    replaceText,
    setReplaceText,
    reasultIndex,
    setReasultIndex,
    resultCount,
    setResultCount,
    searchMatchIndexes,
    setSearchMatchIndexes,
    words,
    setWords,
    showReplace,
    setShowReplace,
  } = props.signal

  const handleReplaceText = props.onReplaceText
  const handleReplaceAllText = props.onReplaceAllText
  const handleDeleteSelected = props.onDeleteSelected
  const handleBatchDelete = props.onBatchDelete

  //当搜索的内容改变的时候
  createEffect(() => {
    if (!searchText()) {
      setResultCount(0)
      setReasultIndex(0)
      setSearchMatchIndexes([])
      return
    }

    let matchIndexes: number[] = []
    words().forEach((word, index) => {
      if (word.disabled != true && word.value.includes(searchText())) {
        matchIndexes.push(index)
      }
    })
    setSearchMatchIndexes(matchIndexes)
    if (matchIndexes.length) {
      //有搜到内容
      // 尽量保留之前的 index 值
      if (reasultIndex() < 1) {
        setReasultIndex(1)
      }
      if (reasultIndex() > matchIndexes.length) {
        setReasultIndex(matchIndexes.length)
      }
      if (resultCount() !== matchIndexes.length) {
        setResultCount(matchIndexes.length)
      }
    } else {
      setReasultIndex(0)
      setResultCount(0)
    }
  })

  return (
    <div class="relative right-0 top-0 mt-2 w-full rounded-md border bg-white p-4 shadow-lg">
      <div class="flex items-center space-x-2">
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant={"ghost"}
              onClick={() => setShowReplace(!showReplace())}
            >
              <Show when={showReplace()}>
                <ChevronDown class="h-5 w-5" />
              </Show>
              <Show when={!showReplace()}>
                <ChevronRight class="h-5 w-5" />
              </Show>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>{showReplace() ? "收起" : "展开"}</p>
          </TooltipContent>
        </Tooltip>

        <Input
          placeholder="搜索文字"
          value={searchText()}
          onInput={(e) => setSearchText(e.target.value)}
          class="w-1/2 flex-1"
        />
        <Show when={resultCount()}>
          <span>
            {reasultIndex()}/{resultCount()}
          </span>
        </Show>

        <Tooltip>
          <TooltipTrigger>
            <Button
              variant={"ghost"}
              disabled={!searchText() || !reasultIndex() || !resultCount()}
              onClick={() => setReasultIndex(Math.max(1, reasultIndex() - 1))}
            >
              <ArrowUpIcon class="h-5 w-5" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>上一个</p>
          </TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant={"ghost"}
              disabled={
                !searchText() ||
                !reasultIndex() ||
                !resultCount() ||
                reasultIndex() == resultCount()
              }
              onClick={() =>
                setReasultIndex(Math.min(resultCount(), reasultIndex() + 1))
              }
            >
              <ArrowDownIcon class="h-5 w-5" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>下一个</p>
          </TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant="outline"
              class="p-2"
              disabled={!searchText() || !reasultIndex() || !resultCount()}
              onClick={() =>
                handleDeleteSelected?.(searchMatchIndexes()[reasultIndex() - 1])
              }
            >
              <Trash class="h-5 w-5" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>删除选中</p>
          </TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant="outline"
              class="p-2"
              disabled={!searchText() || !resultCount()}
              onClick={() => handleBatchDelete?.(searchMatchIndexes())}
            >
              <Trash2Icon class="h-5 w-5" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>批量删除</p>
          </TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant="ghost"
              class="p-2"
              onClick={() => setIsExpanded(false)}
            >
              <X />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>关闭</p>
          </TooltipContent>
        </Tooltip>
      </div>
      {/* 🔖 第二行 */}

      <Show when={showReplace()}>
        <div class="mt-2 flex items-center space-x-2">
          {/* 纯占位  */}
          <div style={{ visibility: "hidden" }}>
            <Tooltip>
              <TooltipTrigger>
                <Button
                  variant={"ghost"}
                  onClick={() => setShowReplace(!showReplace())}
                >
                  <Show when={showReplace()}>
                    <ChevronDown class="h-5 w-5" />
                  </Show>
                  <Show when={!showReplace()}>
                    <ChevronRight class="h-5 w-5" />
                  </Show>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>展开</p>
              </TooltipContent>
            </Tooltip>
          </div>
          <Input
            placeholder="替换文字"
            class="w-1/2 flex-1"
            value={replaceText()}
            onInput={(e) => setReplaceText(e.target.value)}
          />
          <Tooltip>
            <TooltipTrigger>
              <Button
                variant="outline"
                class="p-2"
                disabled={!searchText() || !reasultIndex() || !resultCount()}
                onClick={() =>
                  handleReplaceText?.(
                    searchText(),
                    replaceText(),
                    searchMatchIndexes()[reasultIndex() - 1],
                  )
                }
              >
                <ReplaceIcon class="h-5 w-5" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>替换</p>
            </TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger>
              <Button
                variant="outline"
                class="p-2"
                disabled={!searchText() || !resultCount()}
                onClick={() =>
                  handleReplaceAllText?.(searchText(), replaceText())
                }
              >
                <ReplaceAllIcon class="h-5 w-5" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>替换全部</p>
            </TooltipContent>
          </Tooltip>
        </div>
      </Show>
    </div>
  )
}
