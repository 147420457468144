// src/components/EditWord.tsx
import { createSignal, onCleanup } from "solid-js"
import { Input } from "~/components/ui/input"
import { Button } from "~/components/ui/button"
import { Save, X } from "lucide-solid"

export function createEditWordSignal(config: {
  onSave: () => void
  onCancel: () => void
}) {
  const [text, setText] = createSignal("")
  return { ...config, text, setText }
}

export default function EditWord(props: {
  signal: ReturnType<typeof createEditWordSignal>
}) {
  const signal = props.signal

  const [isComposed, setIsComposed] = createSignal(false)

  return (
    <div class="flex items-center space-x-2">
      <Input
        value={signal.text()}
        onCompositionStart={() => setIsComposed(true)}
        onCompositionEnd={() => setIsComposed(false)}
        // 回车出发 onSave 函数
        onKeyDown={(e) => {
          // 检测是否为中文输入法
          console.log(e.isComposing)
          if (e.key === "Enter" && !isComposed()) {
            signal.onSave()
          }
        }}
        onInput={(e) => signal.setText(e.target.value)}
      />
      <Button onClick={() => signal.onSave()}>
        <Save class="h-4 w-4" />
      </Button>
      <Button variant="outline" onClick={signal.onCancel}>
        <X class="h-4 w-4" />
      </Button>
    </div>
  )
}
