import to from "await-to-js"
import { Word } from "./bdb"
/**
 * @param filename 导出的文件名
 */
export async function exportVideoByWordPart(
  words: (Word & { file: string })[],
  filename: string,
  file: File,
  saveDir: FileSystemDirectoryHandle,
) {
  let segments: {
    /** 单位是 ms */
    start: number
    /** 单位是 ms */
    end: number
    text?: string
    file: string
  }[] = []
  for (let i = 0; i < words.length; i++) {
    let word = words[i]

    if (word.disabled || word.adjustedEnd === word.start) {
      continue //被删除的片段、没有内容的片段
    }

    if (word.type !== "text" && word.type !== "silent") {
      continue //不是有效音频片段，可能是其他的 ui片段，比如说换行之类的
    }

    let wordEnd = word.adjustedEnd || word.end

    if (segments.length === 0) {
      segments.push({
        start: word.start,
        end: wordEnd,
        text: word.value,
        file: word.file || "",
      })
    } else {
      //能续上的话，就续上
      if (segments[segments.length - 1].end == word.start) {
        segments[segments.length - 1].text += word.value
        segments[segments.length - 1].end = wordEnd
      } else {
        // 新的开始
        segments.push({
          start: word.start,
          end: wordEnd,
          text: word.value,
          file: word.file || "",
        })
      }
    }
  }

  let duration = 0
  segments.forEach((segment) => (duration += segment.end - segment.start))
  console.log("video 计算出来的视频长度", duration)
  // return await window.api?.exportVideo(filename, segments)
  const fileHandle = await saveDir.getFileHandle(filename, { create: true })
  return await exportVideoByWebAV(fileHandle, segments, file)
}

async function exportVideoByWebAV(
  fileHandle: FileSystemFileHandle,
  segments: { start: number; end: number }[],
  file: File,
) {
  const { Combinator, MP4Clip, OffscreenSprite } = await import(
    "@webav/av-cliper"
  )

  // TODO 先实现导出单文件的功能
  let mp4clip = new MP4Clip(fileToReadableStream(file))

  /**
   *
   * @param start 单位：ns
   * @param end 单位：ns
   */
  async function getClip(start: number, end: number) {
    let [first] = await mp4clip.split(end)
    if (start == 0) {
      return first
    } else {
      let [_, result] = await first.split(start || 1)
      return result
    }
  }

  const [error, dimensions] = await to(getVideoDimensions(file))

  if (error) {
    throw error
  }
  const { width, height } = dimensions

  const com = new Combinator({
    width: width,
    height: height,
    bitrate: 20_000_000,
    // videoCodec: "avc1.640029",、// 默认 avc1.42E032
    __unsafe_hardwareAcceleration__: "no-preference",
  })

  let offset = 0
  for (let i = 0; i < segments.length; i++) {
    let segment = segments[i]
    let clip = await getClip(segment.start * 1000, segment.end * 1000)
    const spr = new OffscreenSprite(clip)
    spr.time.offset = offset
    await com.addSprite(spr)
    offset += (segment.end - segment.start) * 1000
  }

  await com.output().pipeTo(await fileHandle.createWritable())
  return offset / 1000
}

function fileToReadableStream(file: File): ReadableStream<Uint8Array> {
  const reader = new FileReader()
  const fileStream = new ReadableStream({
    start(controller) {
      reader.onload = () => {
        const arrayBuffer = reader.result as ArrayBuffer
        const uint8Array = new Uint8Array(arrayBuffer)
        controller.enqueue(uint8Array)
        controller.close()
      }
      reader.onerror = () => {
        controller.error(new Error("Failed to read the file."))
      }
      reader.readAsArrayBuffer(file)
    },
    cancel() {
      reader.abort()
    },
  })
  return fileStream
}

async function getVideoDimensions(
  file: File,
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video")
    video.preload = "metadata"

    video.onloadedmetadata = () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
      })
    }

    video.onerror = (error) => {
      reject(error)
    }

    video.src = URL.createObjectURL(file)
  })
}

/**
 * @param filename 导出的文件名
 */
export async function exportVideoSubtitleByWordPart(
  words: (Word & { file: string })[],
  filename: string,
  duration: number,
  wordsPerLine = 1000,
  saveDir: FileSystemDirectoryHandle,
) {
  let textLines: { text: string; start: number; end: number }[][] = [[]]
  let start = 0
  for (let i = 0; i < words.length; i++) {
    let word = words[i]
    if (word.disabled || word.adjustedEnd === word.start) {
      continue //被删除的片段、没有内容的片段
    }

    // 以标点、静音、文本带标点 为分割节点 或者 每行的字数超过了1000个
    if (word.type == "punct") {
      //换行
      if (textLines[textLines.length - 1].length == 0) {
      } else {
        textLines.push([])
      }
      continue
    }
    if (word.type == "silent") {
      textLines.push([
        {
          text: "",
          start: start,
          end: start + (word.end - word.start),
        },
      ])
      start = start + (word.end - word.start)
      continue
    }

    if (word.type == "text") {
      textLines[textLines.length - 1].push({
        text: word.value,
        start: start,
        end: start + (word.end - word.start),
      })

      let re = /.*[，。！？]$/
      if (
        re.test(word.value) ||
        textLines[textLines.length - 1].length >= wordsPerLine
      ) {
        textLines.push([])
      }

      start = start + (word.end - word.start)
      continue
    }
  }

  // 删除空行
  textLines = textLines.filter((line) => line.length > 0)

  let lineSegments: { text: string; start: number; end: number }[] = []
  for (let i = 0; i < textLines.length; i++) {
    let line = textLines[i]
    let texts = line.reduce((acc, cur) => {
      return acc + cur.text
    }, "")
    lineSegments.push({
      start: line[0].start,
      end: line[line.length - 1].end,
      text: texts,
    })
  }

  // fix 时间，等比例缩放   只导出字幕，没有视频时长
  if (duration) {
    let scale = duration / start
    lineSegments.forEach((segment, index) => {
      segment.start = segment.start * scale
      segment.end = segment.end * scale
    })
  }

  function formatTime(seconds) {
    const date = new Date(seconds)
    const hours = date.getUTCHours().toString().padStart(2, "0")
    const minutes = date.getUTCMinutes().toString().padStart(2, "0")
    const secs = date.getUTCSeconds().toString().padStart(2, "0")
    const ms = date.getUTCMilliseconds().toString().padStart(3, "0")

    return `${hours}:${minutes}:${secs},${ms}`
  }

  let srtContent = ""
  lineSegments.forEach((segment, index) => {
    // 添加字幕序号
    srtContent += index + 1 + "\n"
    // 添加时间戳
    const startTime = formatTime(segment.start)
    const endTime = formatTime(segment.end)
    srtContent += `${startTime} --> ${endTime}\n`
    // 添加文本
    srtContent += segment.text + "\n\n"
  })

  const fileHandle = await saveDir.getFileHandle(filename, { create: true })
  const writable = await fileHandle.createWritable()
  await writable.write(srtContent)
  await writable.close()
}
