import {
  createSignal,
  onMount,
  Index,
  createEffect,
  Show,
  createMemo,
  onCleanup,
  batch,
} from "solid-js"

import { Project, Word, bdb, doc2words } from "../../lib/bdb"
import { useNavigate, useParams } from "@solidjs/router"

import { showToast as toast } from "../../components/ui/toast"
import { Button } from "../../components/ui/button"
import Uploader from "../../components/Uploader"
import { Icons } from "../../components/icons"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover"
import {
  ArrowLeftIcon,
  Copy,
  CornerLeftDown,
  HardDriveDownload,
  Highlighter,
  RedoIcon,
  Scissors,
  SearchIcon,
  SpellCheck2,
  Trash,
  Undo,
  UndoIcon,
} from "lucide-solid"
import { Input } from "../../components/ui/input"
import { cn } from "../../lib/utils"
import { Label } from "../../components/ui/label"

// import "../ProjectPage.css"
import {
  CreateRecTaskResponse,
  DescribeTaskStatusResponse,
} from "tencentcloud-sdk-nodejs-asr/tencentcloud/services/asr/v20190614/asr_models"
import { POST } from "../api/speech2text"
import { to } from "await-to-js"
import dayjs from "dayjs"
import {
  Switch,
  SwitchControl,
  SwitchLabel,
  SwitchThumb,
} from "../../components/ui/switch"
import Video, { createVideoSignal } from "../../components/Video"
import { Checkbox } from "../../components/ui/checkbox"
import {
  exportVideoByWordPart,
  exportVideoSubtitleByWordPart,
} from "../../lib/exportVideo"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../components/ui/tooltip"
import SearchBox, { createSearchSignal } from "../../components/Search"
import EditWord, { createEditWordSignal } from "../../components/EditWord"
// import { Progress } from "../../components/ui/progress"

const TASK_STATUS = {
  "-1": "未知",
  0: "排队中",
  1: "进行中",
  2: "成功",
  3: "失败",
}
// 🔖
export default function ProjectPage() {
  const params = useParams()
  const navigate = useNavigate()

  const [hiddenDelete, sethiddenDelete] = createSignal(false)

  const [silentLength, setSilentLength] = createSignal(0.5)

  const [history, setHistory] = createSignal<Project[]>([])
  const [historyIndex, setHistoryIndex] = createSignal(0)
  const project = () => history()[historyIndex()]

  const [tooltip, setTooltip] = createSignal({
    visible: false,
    rect: { top: 0, left: 0 },
  })
  let docContainerRef!: HTMLDivElement
  let tooltipBarRef: HTMLDivElement | undefined

  // 当前视频的播放进度 单位是s； video 的 currentTime 单位是秒，word 的 start 和 end 单位是毫秒
  let videoSignal = createVideoSignal()
  let videoSource = createMemo(() => project()?.mainSource)
  let [currentPlayWordIndex, setCurrentPlayWordIndex] = createSignal<
    number | null
  >(null)
  let [isPreviewPlaying, setIsPreviewPlaying] = createSignal(false)
  createEffect(() => {
    if (videoSignal.isPlaying()) {
      setIsPreviewPlaying(true)
    } else {
      //结束的话，由于有时候会自动播放完成，所以这里就判断一下
      if (words() && currentPlayWordIndex() === words()!.length - 1) {
        setIsPreviewPlaying(false)
        setCurrentPlayWordIndex(null)
      }
    }
  })
  let words = createMemo(() => project()?.words)
  // 🔖 🎞️ 视频播放
  // 初始化刚开始播放的 word index
  createEffect(() => {
    if (isPreviewPlaying() && currentPlayWordIndex() == null) {
      setCurrentPlayWordIndex(0)
    }
  })
  const currentWord = createMemo(() => words()?.[currentPlayWordIndex() || 0])
  createEffect(function handlePlay() {
    console.log("handlePlay")
    if (!words() || !isPreviewPlaying()) {
      return
    }

    let current = currentWord()!
    if (!current) {
      videoSignal.setIsPlaying(false)
      setIsPreviewPlaying(false)
      setCurrentPlayWordIndex(null)
      return
    }

    if (current.disabled) {
      let nextWordIndex = words()?.findIndex(
        (word, index) => index > currentPlayWordIndex()! && !word.disabled,
      )
      if (nextWordIndex !== undefined && nextWordIndex !== -1) {
        // 也意味着不连续
        videoSignal.setCurrentPlayingTime(
          (words()![nextWordIndex!].start + 1) / 1000,
        )
        setCurrentPlayWordIndex(nextWordIndex)
        return
      } else {
        videoSignal.setIsPlaying(false)
        setIsPreviewPlaying(false)
        setCurrentPlayWordIndex(null)
      }
      return
    }
    let currentTime = videoSignal.currentPlayingTime() * 1000
    let wordStart = current.start
    let wordEnd = current.adjustedEnd || current.end
    // 正常的可播放的单词
    if (currentTime >= wordStart && currentTime < wordEnd) {
      // 当前时间在单词范围内,不需要调整
      return
    }
    function findSegmentEnd(wordIndex: number) {
      let endTime = current.adjustedEnd || current.end
      // 从当前index开始查找能续上的片段的结束时间
      let endIndex = currentPlayWordIndex()! + 1
      while (endIndex < words()!.length) {
        let nextWord = words()![endIndex]
        if (nextWord.type == "punct") {
          endIndex++
          continue
        }

        if (!nextWord.disabled && nextWord.start == endTime) {
          // TODO 文件id也相同
          endTime = nextWord.adjustedEnd || nextWord.end
          endIndex++
        } else {
          break
        }
      }
      return { endTime, endIndex }
    }

    // 判断是不是在当前段里，如果是的话，只调整 currentWordIndex
    let { endTime, endIndex } = findSegmentEnd(currentPlayWordIndex()!)
    if (currentTime >= wordStart && currentTime < endTime) {
      // 调整到下一个单词
      let nextWordIndex = words()?.findIndex(
        (word, index) =>
          index > currentPlayWordIndex()! &&
          !word.disabled &&
          word.start <= currentTime &&
          word.end > currentTime,
      )
      if (nextWordIndex !== undefined && nextWordIndex !== -1) {
        setCurrentPlayWordIndex(nextWordIndex)
        // videoSignal.setCurrentPlayingTime((words()![nextWordIndex!].start+1) / 1000)
      } else {
        videoSignal.setIsPlaying(false)
        setIsPreviewPlaying(false)
        setCurrentPlayWordIndex(null)
      }
      return
    }

    // 如果不在当前连续的段里的话
    let nextWordIndex = words()?.findIndex(
      (word, index) => index > endIndex && !word.disabled,
    )
    if (nextWordIndex !== undefined && nextWordIndex !== -1) {
      let nextWord = words()![nextWordIndex!]
      console.log(nextWord)
      setCurrentPlayWordIndex(nextWordIndex)
      videoSignal.setCurrentPlayingTime(
        (words()![nextWordIndex!].start + 1) / 1000,
      )
      videoSignal.setIsPlaying(true)
    } else {
      videoSignal.setIsPlaying(false)
      setIsPreviewPlaying(false)
      setCurrentPlayWordIndex(null)
    }
  })

  const [speech2textTaskStatus, setSpeech2TextTaskStatus] = createSignal<
    -1 | 0 | 1 | 2 | 3
  >(-1)

  const [projectTitle, setProjectTitle] = createSignal("")
  createEffect(() => {
    if (project()) {
      setProjectTitle(project().title)
    }
  })

  function startFetchSpeech2TextTaskInfoTimer(
    taskId: number,
    immediate = true,
  ) {
    async function fetchSpeech2TextTaskInfo() {
      try {
        const res = await fetch(`/api/speech2text/${taskId}`)
        const json = (await res.json()) as {
          code: number
          data: DescribeTaskStatusResponse
          message?: string
        }
        if (!json.data.Data) {
          return
        }
        // 如果任务状态是成功或者失败，停止轮询
        if (json.data.Data.Status == 2) {
          clearInterval(timer)
          setSpeech2TextTaskStatus(json.data.Data.Status)
          let words = doc2words(json.data.Data.ResultDetail)
          setHistory([
            ...history(),
            {
              ...project(),
              words: words,
              speech2textTaskStatus: json.data.Data.Status,
            },
          ])
          setHistoryIndex(historyIndex() + 1)
        }
        if (json.data.Data.Status == 3) {
          clearInterval(timer)
          setSpeech2TextTaskStatus(json.data.Data.Status)
          setHistory([
            ...history(),
            {
              ...project(),
              speech2textTaskStatus: json.data.Data.Status,
            },
          ])
        }
      } catch (error) {
        clearInterval(timer)
        toast({
          title: "错误",
          description: (error as Error).message,
          variant: "destructive",
        })
      }
    }
    const timer = setInterval(fetchSpeech2TextTaskInfo, 5000)
    if (immediate) {
      fetchSpeech2TextTaskInfo()
    }
  }

  // 🔖 加载项目信息
  onMount(() => {
    bdb.projects.get(params.id).then((project) => {
      if (project !== undefined) {
        setHistory([project])
        setHistoryIndex(0)

        if (project.speech2textTaskId && !project.words) {
          startFetchSpeech2TextTaskInfoTimer(project.speech2textTaskId)
        }
      }
    })
  })

  // 🔖初始化快捷键
  var mousetrap
  onMount(async () => {
    var mousetrap = await import("mousetrap")
    mousetrap.bind(["backspace", "del"], function () {
      handleEditWords(function (word) {
        word.disabled = true
      }, true)
    })
    mousetrap.bind(["ctrl+z", "command+z"], function () {
      if (historyIndex() > 0) {
        setHistoryIndex(historyIndex() - 1)
      }
    })
    mousetrap.bind(["ctrl+shift+z", "command+shift+z"], function () {
      if (historyIndex() < history().length - 1) {
        setHistoryIndex(historyIndex() + 1)
      }
    })

    mousetrap.bind(["space"], function () {
      videoSignal.setIsPlaying(!videoSignal.isPlaying())
    })
  })

  onCleanup(() => {
    mousetrap?.unbind(["backspace", "del"])
    mousetrap?.unbind(["ctrl+z", "command+z"])
    mousetrap?.unbind(["ctrl+shift+z", "command+shift+z"])
  })

  createEffect(function () {
    console.log(project())
  })

  // 🔖初始化悬浮条事件

  onMount(() => {
    // document.addEventListener("mousedown", (event) => {
    //   setTooltip({ ...tooltip(), visible: false })
    // })
    function handleTooltip(e: MouseEvent) {
      if (tooltipBarRef?.contains(e.target as HTMLElement)) {
        return
      }

      const selectedText = window.getSelection()?.toString()
      if (
        selectedText?.trim().length &&
        docContainerRef.contains(window.getSelection()?.anchorNode ?? null)
      ) {
        const range = window.getSelection()?.getRangeAt(0)
        const rect = range!.getBoundingClientRect()
        const containerRect = docContainerRef.getBoundingClientRect()
        setTooltip({
          visible: true,
          rect: {
            left: 0,
            top: 0,
          },
        })
        setTimeout(() => {
          if (!tooltipBarRef) {
            return
          }
          const tooltiprect = tooltipBarRef.getBoundingClientRect()
          var left =
            rect.left -
            containerRect.left +
            rect.width / 2 -
            tooltiprect.width / 2

          var top =
            rect.top - containerRect.top + docContainerRef.scrollTop - 60

          if (left + tooltiprect.width + 20 > containerRect.width) {
            left = containerRect.width - tooltiprect.width - 20
          }

          if (left < 0) {
            left = 0
          }

          setTooltip({
            visible: true,
            rect: {
              left: left,
              top: top,
            },
          })
        }, 0)
      } else {
        setTooltip({ ...tooltip(), visible: false })
      }
    }
    document.addEventListener("mouseup", handleTooltip)

    onCleanup(() => {
      document.removeEventListener("mouseup", handleTooltip)
    })
  })

  // 🔖 剪切、复制、粘贴 功能
  const [clipboard, setClipboard] = createSignal<Word[] | null>(null)

  // 🔖 转录时候的loading
  const [isParseTaskCreating, setIsTaskCreating] = createSignal<boolean>(false)

  const [isExporting, setIsExporting] = createSignal<boolean>(false)
  const [exportVideo, setExportVideo] = createSignal(false)
  const [exportHightlights, setExportHightlights] = createSignal(false)
  const [exportSubtitle, setExportSubtitle] = createSignal(false)
  const [singleWordPerLine, setSingleWordPerLine] = createSignal(false)

  // 调用接口导出视频
  async function handleExportVideo() {
    setIsExporting(true)
    const saveDir = await window.showDirectoryPicker()
    if (!saveDir == null) {
      setIsExporting(false)
      return
    }

    type WordExportModel = Word & { file: string }
    var words = project()?.words! as WordExportModel[]
    var filename = project().mainSourceRealPath || project().mainSource?.name!
    words.forEach((word) => (word.file = filename))

    // 每行最多显示多少字
    let wordsPerLine = 100
    if (singleWordPerLine()) {
      wordsPerLine = 1
    }
    try {
      var savedFilenameBase = `${filename.split(".")[0]}—${dayjs().format("YYYY-MM-DD-HH-mm-ss")}`

      var duration = 0
      if (exportVideo()) {
        duration = await exportVideoByWordPart(
          words,
          savedFilenameBase + ".mp4",
          project().mainSource!,
          saveDir,
        )
      }

      if (exportSubtitle()) {
        await exportVideoSubtitleByWordPart(
          words,
          savedFilenameBase + ".srt",
          duration,
          wordsPerLine,
          saveDir,
        )
      }

      // 导出精彩片段
      if (exportHightlights()) {
        await exportExportHightlights()
        async function exportExportHightlights() {
          let highlights: (WordExportModel & { indexInWords: number })[][] = []
          words.forEach((word, indexInWords) => {
            if (word.highlight) {
              if (highlights.length == 0) {
                highlights.push([{ ...word, indexInWords: indexInWords }])
              } else {
                let last = highlights[highlights.length - 1]
                if (last[last.length - 1].indexInWords + 1 == indexInWords) {
                  //连续的高亮片段
                  last.push({ ...word, indexInWords: indexInWords })
                } else {
                  highlights.push([{ ...word, indexInWords: indexInWords }])
                }
              }
            }
          })
          // 依次导出精彩片段
          for (let index = 0; index < highlights.length; index++) {
            const element = highlights[index]

            let duration = await exportVideoByWordPart(
              element,
              `${savedFilenameBase}-hightlight-${index + 1}.mp4`,
              project().mainSource!,
              saveDir,
            )
            await exportVideoSubtitleByWordPart(
              element,
              `${savedFilenameBase}-hightlight-${index + 1}.srt`,
              duration,
              wordsPerLine,
              saveDir,
            )
          }
        }
      }
    } catch (error) {
      return toast({
        title: "Something went wrong.",
        description: (error as Error).message,
        variant: "destructive",
      })
    } finally {
      setIsExporting(false)
    }
    return toast({
      description: "导出成功",
    })
  }

  async function handleSaveToDraft() {
    await bdb.projects.update(project().id!, { ...project() })
    toast({ description: "保存成功" })
  }
  // 自动存草稿
  createEffect(async () => {
    let project = history()[historyIndex()]
    if (project) {
      await bdb.projects.update(project.id, { ...project })
    }
  })

  // select 更新 自动更新 selectWordIndexes
  let [selectedWords, setSelectedWords] = createSignal<Word[]>([])
  function handleSelectionChange() {
    let indexArg = getSelectedWordIndexes()
    if (indexArg) {
      let { startIndex, endIndex } = indexArg
      setSelectedWords(project().words?.slice(startIndex, endIndex + 1) || [])
    } else {
      setSelectedWords([])
    }
  }
  onMount(() => {
    document.addEventListener("selectionchange", handleSelectionChange)

    onCleanup(function () {
      document.removeEventListener("selectionchange", handleSelectionChange)
    })
  })

  function getSelectedWordIndexes() {
    let startIndex = 0
    let endIndex = words.length - 1
    const select = window.getSelection()
    if (select && !select.isCollapsed) {
      // 获取选中元素的 senIndex 和 wordIndex 将 doc 上的对应word disable 掉
      const anchorWordIndex = parseInt(
        select.anchorNode?.parentElement?.dataset.index!,
      )
      const focusWordIndex = parseInt(
        select.focusNode?.parentElement?.dataset.index!,
      )
      startIndex = Math.min(anchorWordIndex, focusWordIndex)
      endIndex = Math.max(anchorWordIndex, focusWordIndex)
      return { startIndex, endIndex }
    }
  }

  async function handleEditWords(
    callback: (word: Word) => void,
    ifNoSelectedIgnore = false,
  ) {
    let words = JSON.parse(JSON.stringify(project().words)) as Word[]
    // 默认是对所有的word进行操作，如果有选中的文本，那么就只对选中的文本进行操作
    let startIndex = 0
    let endIndex = words.length - 1

    // 如果有选中的文本，那么就只对选中的文本进行操作
    const select = window.getSelection()

    if (select && !select.isCollapsed) {
      // 获取选中元素的 senIndex 和 wordIndex 将 doc 上的对应word disable 掉
      const anchorWordIndex = parseInt(
        select.anchorNode?.parentElement?.dataset.index!,
      )
      const focusWordIndex = parseInt(
        select.focusNode?.parentElement?.dataset.index!,
      )
      startIndex = Math.min(anchorWordIndex, focusWordIndex)
      endIndex = Math.max(anchorWordIndex, focusWordIndex)
    } else {
      if (ifNoSelectedIgnore) {
        return
      }
    }

    words.forEach((word, index) => {
      if (index >= startIndex && index <= endIndex) {
        callback(word)
      }
    })
    handlePushIntoHistoryOfWords(words)
  }

  async function handlePushIntoHistoryOfWords(newWords: Word[]) {
    // 获取当前 history 中之前的 history
    setHistory([
      ...history().slice(0, historyIndex() + 1),
      { ...project(), words: newWords },
    ])
    setHistoryIndex(historyIndex() + 1)
  }
  async function handlePushIntoHistoryOfProject(project: Project) {
    setHistory([...history().slice(0, historyIndex() + 1), { ...project }])
    setHistoryIndex(historyIndex() + 1)
  }

  // 🔖 🔍 搜索框
  const searchSignal = createSearchSignal()
  createEffect(() => {
    searchSignal.setWords(project()?.words || [])
  })
  createEffect(() => {
    let currentEl = document.querySelector(
      `#wordsContent span[data-index="${
        searchSignal.searchMatchIndexes()[searchSignal.reasultIndex() - 1] || -1
      }"]`,
    )
    if (currentEl) currentEl.scrollIntoView()
  })

  // 🔖 编辑文字相关的
  const [isEditingWord, setIsEdtingWord] = createSignal(false)
  const [editingWordIndex, setEditingWordIndex] = createSignal<number | null>(
    null,
  )
  createEffect(function () {
    // 失去焦点的时候自动隐藏编辑框
    if (!tooltip().visible) {
      setIsEdtingWord(false)
    }
  })

  const editSignal = createEditWordSignal({
    onSave: () => {
      let words = structuredClone(project().words) as Word[]
      words[editingWordIndex()!].value = editSignal.text()
      handlePushIntoHistoryOfWords(words)
      setIsEdtingWord(false)
      setEditingWordIndex(null)
      editSignal.setText("")
      setTooltip({ ...tooltip(), visible: false })
    },
    onCancel: () => {
      setIsEdtingWord(false)
      setEditingWordIndex(null)
      editSignal.setText("")
      setTooltip({ ...tooltip(), visible: false })
    },
  })

  // 🔖 🔖🔖🔖开始渲染🔖🔖🔖 🔖
  return (
    <div class="flex h-screen">
      <div id="canvas"></div>
      <Show when={project()} fallback="加载中...">
        <div class="relative flex h-full w-full flex-col bg-white p-6">
          {/* 标题栏 */}
          <div class="mb-4 flex items-center">
            {/* 返回按钮 */}
            <a class="mr-1 flex-none" href="#">
              <Button
                variant="ghost"
                onClick={() => {
                  navigate(-1)
                }}
              >
                <ArrowLeftIcon class="h-5 w-5" />
                <span class="sr-only">Back</span>
              </Button>
            </a>
            {/* 标题 */}
            <div class="flex min-w-4 flex-shrink items-center pr-1">
              <Input
                // contentEditable={isEditingTitle()}
                size={projectTitle().length || 1}
                class="w-auto min-w-4 max-w-full border-0 bg-transparent text-lg font-bold hover:border-gray-500 hover:bg-gray-100"
                // @ts-ignore
                style={{ "field-sizing": "content" }}
                value={projectTitle()}
                onInput={(el) => setProjectTitle(el.target.value)}
                onChange={(event) => {
                  let newValues = event.target.value
                  if (newValues != project().title) {
                    let newProject = {
                      ...project(),
                      title: newValues,
                    } as Project
                    handlePushIntoHistoryOfProject(newProject)
                  }
                }}
              ></Input>
            </div>

            {/* 右侧的按钮 历史-撤销-导出 */}
            <div class="ml-auto flex flex-none items-center space-x-2">
              <Button
                size="icon"
                variant="ghost"
                onClick={(e) => {
                  setHistoryIndex(historyIndex() - 1)
                }}
                disabled={historyIndex() - 1 < 0}
              >
                <UndoIcon class="h-4 w-4" />
                <span class="sr-only">Undo</span>
              </Button>
              <Button
                size="icon"
                variant="ghost"
                onClick={(e) => {
                  setHistoryIndex(historyIndex() + 1)
                }}
                disabled={historyIndex() >= history().length - 1}
              >
                <RedoIcon class="h-4 w-4" />
                <span class="sr-only">Redo</span>
              </Button>
              {/* 导出按钮 */}
              <Show when={project().mainSource && words()}>
                <Popover>
                  <PopoverTrigger>
                    <Button size="lg" variant="outline">
                      导出
                      <HardDriveDownload class="ml-2 h-5 w-5" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent class="rounded-lg p-6 shadow-lg sm:max-w-[425px]">
                    <div class="grid gap-4 py-4">
                      <div class="flex items-center space-x-4">
                        <Checkbox
                          id="video"
                          checked={exportVideo()}
                          onChange={setExportVideo}
                        />
                        <Label for="video-input" class="font-medium">
                          视频
                        </Label>
                        {/* <div class="flex items-center space-x-2">
                          <Checkbox id="captions" />
                          <Label for="captions" class="text-muted-foreground">
                            {/* Include captions
                            内嵌字幕
                          </Label>
                        </div> */}
                      </div>
                      <div class="flex items-center space-x-4">
                        <Checkbox
                          id="highlights"
                          checked={exportHightlights()}
                          onChange={setExportHightlights}
                        />
                        <Label for="highlights-input" class="font-medium">
                          {/* Highlight clips */}
                          精彩片段（分段）
                        </Label>
                      </div>
                      {/* <div class="flex items-center space-x-4">
                        <Checkbox id="highlight-video" />
                        <Label for="highlight-video" class="font-medium">
                          Highlight video
                        </Label>
                      </div> */}
                      <div class="flex items-center space-x-4">
                        <Checkbox
                          id="subtitle"
                          checked={exportSubtitle()}
                          onChange={setExportSubtitle}
                        />
                        <Label for="subtitle-input" class="font-medium">
                          字幕文件
                        </Label>
                        <Show when={exportSubtitle()}>
                          <Checkbox
                            id="singleWordPerLine"
                            checked={singleWordPerLine()}
                            onChange={setSingleWordPerLine}
                          />
                          <Label
                            for="singleWordPerLine-input"
                            class="font-medium"
                          >
                            单个字或词语
                          </Label>
                        </Show>
                      </div>
                      {/* <div class="flex items-center space-x-4">
                        <Checkbox id="metadata" />
                        <Label for="metadata" class="font-medium">
                          Video metadata
                        </Label>
                      </div> */}
                    </div>
                    <div class="mt-6 flex justify-end gap-2">
                      {/* <Button variant="outline">取消</Button> */}
                      {/* <Button
                        variant="outline"
                        onClick={() => {
                          navigator.clipboard.writeText(JSON.stringify(words()))
                          toast({
                            description: "已复制到剪切板",
                            duration: 2000,
                          })
                        }}
                      >
                        <span> 导出文档到剪切板</span>
                      </Button> */}
                      <Button
                        // variant="outline"
                        onClick={handleExportVideo}
                        disabled={
                          isExporting() ||
                          (!exportVideo() &&
                            !exportHightlights() &&
                            !exportSubtitle())
                        }
                      >
                        <Show when={isExporting()}>
                          <Icons.spinner class="mr-2 inline-block h-4 w-4 animate-spin" />
                        </Show>
                        导出
                      </Button>
                    </div>
                  </PopoverContent>
                </Popover>
              </Show>
            </div>
          </div>

          {/* 导入按钮 */}
          <Show when={!project().mainSource}>
            <Uploader
              onChange={(event) => {
                if (event.target.files) {
                  const file = event.target.files![0]
                  var newProject = {
                    ...project(),
                    mainSource: file,
                    mainSourceRealPath: file.path,
                  } satisfies Project
                  // 导入文件的时候如何没有修改过标题，则使用文件名
                  if (
                    project().title ==
                    dayjs(project().createdAt).format("YYYY-MM-DD HH:mm:ss")
                  ) {
                    newProject.title = file.name
                  }
                  if (file.name) setHistory([...history(), newProject])
                  setHistoryIndex(historyIndex() + 1)
                }
              }}
            ></Uploader>
          </Show>

          {/* 解析成文档按钮 */}
          <Show when={project().mainSource && !words()}>
            <div class="mb-4 flex h-screen flex-col items-center justify-center space-y-2">
              <h2>{project()?.mainSource?.name}</h2>
              <Show when={speech2textTaskStatus() !== -1}>
                <div>
                  <h2>
                    解析进度：
                    <Show
                      when={
                        speech2textTaskStatus() == 0 ||
                        speech2textTaskStatus() == 1
                      }
                    >
                      <Icons.spinner class="mr-2 inline-block h-4 w-4 animate-spin" />
                    </Show>
                    {TASK_STATUS[speech2textTaskStatus()]}
                  </h2>
                </div>
              </Show>

              {/* 没有 taskId 或者解析失败的时候才显示按钮 */}
              <Show
                when={
                  !project().speech2textTaskId || speech2textTaskStatus() === 3
                }
              >
                <Button
                  class="flex"
                  onClick={async function handleCreateSpeech2TextTask() {
                    // 调用接口转录
                    var task: CreateRecTaskResponse | undefined = undefined
                    // if (
                    //   project().mainSource?.type.includes("video") &&
                    //   !window.api
                    // ) {
                    //   return toast({
                    //     title: "视频文件仅在客户端中支持上传解析",
                    //     description: "请在客户端上传视频文件",
                    //     variant: "destructive",
                    //   })
                    // }

                    // let audio: Blob | undefined = undefined
                    // if (project().mainSource?.type.includes("video")) {
                    //   let [error, buffer] = await to(
                    //     window.api!.ffmpeg(
                    //       [
                    //         "-i",
                    //         project().mainSourceRealPath!,
                    //         "-vn",
                    //         "-f",
                    //         "mp3",
                    //         "-loglevel",
                    //         "error",
                    //         "pipe:1",
                    //       ],
                    //       true,
                    //     ),
                    //   )
                    //   if (error) {
                    //     return toast({
                    //       title: "错误",
                    //       description: "网络错误",
                    //       variant: "destructive",
                    //     })
                    //   }
                    //   audio = new Blob([buffer], { type: "audio/mp3" })
                    // }
                    let formData = new FormData()
                    // formData.append("file", audio ?? project().mainSource!)
                    // 腾讯云的限制：音频限制：音频 URL 时长不能大于5小时，文件大小不超过1GB；
                    //
                    let file = project().mainSource!
                    // 先限制100M吧1e7  1G:1e9
                    if (file.size > 100e6) {
                      return toast({
                        title: "文件过大",
                        description: "请上传小于100M的文件",
                        variant: "destructive",
                      })
                    }
                    //  以秒为单位
                    let { duration } = await new Promise<HTMLAudioElement>(
                      (resolve, reject) => {
                        const url = URL.createObjectURL(file)
                        // 根据文件类型创建相应的媒体元素
                        const media = file.type.startsWith("audio/")
                          ? new Audio()
                          : document.createElement("video")
                        media.src = url
                        // 监听 loadedmetadata 事件来获取时长
                        media.onloadedmetadata = () => {
                          URL.revokeObjectURL(url)
                          resolve(media)
                        }

                        // 处理可能的错误
                        media.onerror = () => {
                          URL.revokeObjectURL(url)
                          reject(new Error("无法获取文件信息"))
                        }
                      },
                    )

                    if (duration > 5 * 60 * 60) {
                      return toast({
                        title: "时长过长",
                        description: "请上传小于5小时的文件",
                        variant: "destructive",
                      })
                    }

                    formData.append("file", file)

                    setIsTaskCreating(true)
                    let [error, resp] = await to(
                      fetch("/api/speech2text", {
                        method: "POST",
                        body: formData,
                      }),
                    )
                    setIsTaskCreating(false)
                    if (error || !resp) {
                      return toast({
                        title: "错误",
                        description: "网络错误",
                        variant: "destructive",
                      })
                    }

                    // 如果是重定向，那么就跳转到登录页面
                    if (resp.redirected && resp.url) {
                      navigate("/auth/login")
                      return
                    }
                    var json = (await resp.json()) as Awaited<
                      ReturnType<typeof POST>
                    >
                    // 如果后端返回错误信息，比如是欠费
                    if ("error" in json) {
                      return toast({
                        title: "错误",
                        description: json.error,
                        variant: "destructive",
                      })
                    }

                    let taskId = json.task?.Data?.TaskId
                    if (taskId) {
                      setHistory([
                        ...history(),
                        { ...project(), speech2textTaskId: taskId },
                      ])
                      setHistoryIndex(historyIndex() + 1)

                      setSpeech2TextTaskStatus(0)
                      startFetchSpeech2TextTaskInfoTimer(taskId!)
                    } else {
                      return toast({
                        title: "错误",
                        description: "创建解析任务失败",
                        variant: "destructive",
                      })
                    }
                  }}
                  disabled={isParseTaskCreating()}
                >
                  <Show when={isParseTaskCreating()}>
                    <Icons.spinner class="mr-2 h-4 w-4 animate-spin" />
                  </Show>
                  解析成文档
                </Button>
                {/* 或
          <Button
            class="flex"
            onClick={async () => {
              // 从剪切板导入 words
              let wordsText = await navigator.clipboard.readText()
              if (wordsText) {
                let words = JSON.parse(wordsText || "[]") as Word[]
                setHistory([...history(), { ...project(), words: words }])
                setHistoryIndex(historyIndex() + 1)
              }
            }}
          >
            导入文档配置
          </Button> */}
              </Show>
            </div>
          </Show>

          {/* 按钮操作栏 */}
          {/*  删除按钮*/}
          <Show when={project()?.words}>
            <div class="flex flex-col">
              {/* 第一行 */}
              <div class="z-10 flex flex-row items-center bg-white">
                {/* ----删除口水词按钮 */}
                <Button
                  class="mr-2"
                  variant="outline"
                  // size="icon"
                  onClick={() => {
                    handleEditWords(function (word) {
                      if (["嗯", "啊", "哦", "呃"].indexOf(word.value) != -1) {
                        word.disabled = true
                      }
                    })
                  }}
                >
                  <Trash class="mr-2 h-4 w-4" />
                  <span>删除口水词（嗯啊哦呃）</span>
                  <span class="sr-only">删除"嗯" "啊" "哦" "呃"</span>
                </Button>
                <Button
                  class="mr-2"
                  variant="outline"
                  // size="icon"
                  onClick={() => {
                    handleEditWords(function (word) {
                      if (word.type === "silent") {
                        word.disabled = true
                      }
                    })
                  }}
                >
                  <Trash class="mr-2 h-4 w-4" />
                  <span>删除静音</span>
                  <span class="sr-only">删除静音</span>
                </Button>
                <Switch
                  class="flex items-center space-x-2"
                  checked={hiddenDelete()}
                  onChange={sethiddenDelete}
                >
                  <SwitchControl>
                    <SwitchThumb />
                  </SwitchControl>
                  <SwitchLabel>隐藏删除的片段</SwitchLabel>
                </Switch>

                <Show when={!searchSignal.isExpanded()}>
                  <div class="ml-auto">
                    <Tooltip>
                      <TooltipTrigger>
                        <Button
                          variant={"ghost"}
                          onClick={() =>
                            searchSignal.setIsExpanded(
                              !searchSignal.isExpanded(),
                            )
                          }
                        >
                          <SearchIcon class="h-5 w-5" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Search</p>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                </Show>
              </div>

              {/* 第二行 */}
              <div class="z-10 ml-auto w-full">
                {/*  🔖 🔍 搜索框 */}
                <Show when={searchSignal.isExpanded()}>
                  <SearchBox
                    signal={searchSignal}
                    onDeleteSelected={(indexInWords) => {
                      let words = JSON.parse(
                        JSON.stringify(project().words),
                      ) as Word[]
                      let word = words[indexInWords]
                      word.disabled = true

                      handlePushIntoHistoryOfWords(words)
                    }}
                    onBatchDelete={() => {
                      let words = JSON.parse(
                        JSON.stringify(project().words),
                      ) as Word[]
                      searchSignal.searchMatchIndexes().forEach((index) => {
                        let word = words[index]
                        word.disabled = true
                      })

                      handlePushIntoHistoryOfWords(words)
                    }}
                    onReplaceText={(text, replaceText, indexInWords) => {
                      let words = JSON.parse(
                        JSON.stringify(project().words),
                      ) as Word[]
                      let word = words[indexInWords]
                      word.value = word.value.replaceAll(text, replaceText)

                      handlePushIntoHistoryOfWords(words)
                    }}
                    onReplaceAllText={(text, replaceText) => {
                      let words = JSON.parse(
                        JSON.stringify(project().words),
                      ) as Word[]
                      searchSignal.searchMatchIndexes().forEach((index) => {
                        let word = words[index]
                        word.value = word.value.replaceAll(text, replaceText)
                      })

                      handlePushIntoHistoryOfWords(words)
                    }}
                  />
                </Show>
              </div>
              {/*  ----🔇 调整静音按钮 */}
            </div>
            <div
              ref={docContainerRef}
              class="relative -mt-14 h-full overflow-auto pt-16"
            >
              {/* 🔖 浮动条 */}
              <div
                ref={tooltipBarRef!}
                style={{
                  top: tooltip().rect.top + "px",
                  left: tooltip().rect.left + "px",
                }}
                class={cn(
                  "absolute z-20 flex items-center space-x-2 rounded-sm border border-black bg-white px-2 py-1 shadow-lg",
                  {
                    hidden: !tooltip().visible,
                  },
                )}
              >
                {/* 分成上下两行 */}
                <div class="flex flex-col">
                  <div class={cn({ hidden: isEditingWord() })}>
                    {/* 如果所选的有能被删除的 */}
                    <Show
                      when={
                        selectedWords().filter((word) => !word.disabled).length
                      }
                    >
                      <Tooltip>
                        <TooltipTrigger>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={(event) => {
                              handleEditWords(function (word) {
                                word.disabled = true
                              }, true)
                              setTooltip({ ...tooltip(), visible: false })
                            }}
                          >
                            <Trash class="h-4 w-4" />
                            <span class="sr-only">Delete</span>
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Delete</p>
                          <p class="text-xs">Backspace</p>
                        </TooltipContent>
                      </Tooltip>
                    </Show>
                    {/* 显示已删除的内容 并且选中的内容有被删除的片段 */}
                    <Show
                      when={
                        selectedWords().filter((word) => word.disabled)
                          .length && !hiddenDelete()
                      }
                    >
                      <Tooltip>
                        <TooltipTrigger>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => {
                              handleEditWords(function (word) {
                                word.disabled = false
                                word.adjustedEnd = word.end
                              })
                              setTooltip({ ...tooltip(), visible: false })
                            }}
                          >
                            <Undo class="h-4 w-4" />
                            <span class="sr-only">Undo</span>
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>还原</p>
                        </TooltipContent>
                      </Tooltip>
                    </Show>
                    {/* 如果有是否可以设置为精彩片段的 */}
                    <Show
                      when={
                        selectedWords().filter((word) => !word.highlight).length
                      }
                    >
                      <Tooltip>
                        <TooltipTrigger>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => {
                              handleEditWords(function (word) {
                                word.highlight = true
                              })
                              setTooltip({ ...tooltip(), visible: false })
                            }}
                          >
                            <Highlighter class="h-4 w-4" />
                            <span class="sr-only">精彩片段</span>
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>精彩片段</p>
                        </TooltipContent>
                      </Tooltip>
                    </Show>
                    <Show
                      when={
                        selectedWords().filter((word) => word.highlight).length
                      }
                    >
                      <Tooltip>
                        <TooltipTrigger>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => {
                              handleEditWords(function (word) {
                                word.highlight = false
                              })
                              setTooltip({ ...tooltip(), visible: false })
                            }}
                          >
                            <Highlighter class="h-4 w-4 opacity-50" />
                            <span class="sr-only">不设置为精彩片段</span>
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>不设置为精彩片段</p>
                        </TooltipContent>
                      </Tooltip>
                    </Show>

                    <Tooltip>
                      <TooltipTrigger>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => {
                            let indexes = getSelectedWordIndexes()
                            if (!indexes) {
                              return
                            }
                            let words = structuredClone(
                              project().words,
                            ) as Word[]
                            let clipboard = words.slice(
                              indexes.startIndex,
                              indexes.endIndex + 1,
                            )
                            console.log(clipboard)
                            setClipboard(clipboard)
                            setTooltip({ ...tooltip(), visible: false })
                          }}
                        >
                          <Copy class="h-4 w-4" />
                          <span class="sr-only">复制</span>
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>复制</p>
                      </TooltipContent>
                    </Tooltip>

                    <Tooltip>
                      <TooltipTrigger>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => {
                            let indexes = getSelectedWordIndexes()
                            if (!indexes) {
                              return
                            }
                            let words = structuredClone(
                              project().words,
                            ) as Word[]
                            // 更新剪切板
                            let clipboard = words.splice(
                              indexes.startIndex,
                              indexes.endIndex - indexes.startIndex + 1,
                            )
                            setClipboard(clipboard)
                            // 将剪切板的内容插入到当前选中的位置
                            handlePushIntoHistoryOfWords(words)

                            setTooltip({ ...tooltip(), visible: false })
                          }}
                        >
                          <Scissors class="h-4 w-4" />
                          <span class="sr-only">剪切</span>
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>剪切</p>
                      </TooltipContent>
                    </Tooltip>
                    <Show when={clipboard()?.length}>
                      <Tooltip>
                        <TooltipTrigger>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => {
                              let indexes = getSelectedWordIndexes()
                              if (!indexes) {
                                return
                              }
                              if (!clipboard()) {
                                return
                              }
                              let words = structuredClone(
                                project().words,
                              ) as Word[]
                              // 将剪切板的内容插入到当前选中的位置
                              words.splice(
                                indexes.startIndex,
                                0,
                                ...clipboard()!,
                              )
                              batch(() => {
                                // 清空剪切板
                                setClipboard(null)
                                handlePushIntoHistoryOfWords(words)
                                setTooltip({ ...tooltip(), visible: false })
                                if (
                                  currentPlayWordIndex() != null &&
                                  indexes.startIndex <= currentPlayWordIndex()!
                                ) {
                                  setCurrentPlayWordIndex(
                                    currentPlayWordIndex()! +
                                      (indexes.endIndex - indexes.startIndex),
                                  )
                                }
                              })
                            }}
                          >
                            <CornerLeftDown class="h-4 w-4" />
                            <span class="sr-only">粘贴</span>
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>在当前位置前面粘贴</p>
                        </TooltipContent>
                      </Tooltip>
                    </Show>
                    <Tooltip>
                      <TooltipTrigger>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => {
                            // Handle edit click
                            let indexes = getSelectedWordIndexes()
                            if (!indexes) {
                              return
                            }
                            let text =
                              project()!.words![indexes.startIndex].value
                            setEditingWordIndex(indexes.startIndex)
                            editSignal.setText(text)
                            setIsEdtingWord(true)
                            // setTooltip({ ...tooltip(), visible: false });
                          }}
                        >
                          <SpellCheck2 class="h-4 w-4" />
                          <span class="sr-only">修改文字</span>
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Edit</p>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                  <Show when={isEditingWord()}>
                    <EditWord signal={editSignal} />
                  </Show>
                </div>
              </div>
              {/* 🔖 📄文档内容 */}
              <p id="wordsContent" class="break-all">
                <Index each={words()}>
                  {(word, wordIndex) => (
                    <span
                      // key={`${word().start}-${word().value}`}
                      data-index={wordIndex}
                      style="padding: 1px;"
                      class={cn("word select-all", "hover:bg-blue-500", {
                        "bg-yellow-200": word().highlight,
                        // 如果当前的时间在这个word的时间范围内，那么就高亮
                        "bg-blue-500": wordIndex == currentPlayWordIndex(),
                        "font-light text-gray-300 line-through":
                          word().disabled,
                        hidden: hiddenDelete() && word().disabled,

                        // 搜索相关：搜索高亮
                        "bg-blue-300": searchSignal
                          .searchMatchIndexes()
                          .includes(wordIndex),
                        "bg-blue-600":
                          wordIndex ==
                          searchSignal.searchMatchIndexes()[
                            searchSignal.reasultIndex() - 1
                          ], //搜索高亮
                      })}
                      onClick={(event) => {
                        if (word().disabled) {
                          return
                        }
                        // console.log(word())
                        // setIsPreviewPlaying(false)
                        // videoSignal.setIsPlaying(false)
                        // setCurrentPlayWordIndex(null)

                        // 将当前的播放进度改为当前word的start
                        batch(() => {
                          setCurrentPlayWordIndex(wordIndex)
                          videoSignal.setCurrentPlayingTime(
                            (word().start + 1) / 1000,
                          )
                          // setIsPreviewPlaying(true)
                          // videoSignal.setIsPlaying(true)
                        })
                      }}
                      onChange={(event) => {
                        console.log(event.target)
                      }}
                    >
                      {word().type === "silent"
                        ? "[..." +
                          (word().adjustedEnd - word().start) / 1000 +
                          "s]"
                        : word().value}
                    </span>
                  )}
                </Index>
              </p>
            </div>
          </Show>
        </div>
      </Show>
      {/* 预览组件 */}
      <Show when={videoSource()}>
        <div class="flex h-full w-1/2 flex-col bg-gray-900">
          <Video
            control={videoSignal}
            source={URL.createObjectURL(videoSource()!)}
          ></Video>
        </div>
      </Show>
    </div>
  )
}
