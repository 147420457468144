/**
 * v0 by Vercel.
 * @see https://v0.dev/t/GZyLnDzDPBq
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */

import { JSX } from "solid-js"

export default function Uploader({
  onChange,
}: {
  onChange: JSX.ChangeEventHandler<HTMLInputElement, Event>
}) {
  return (
    <div class="flex h-screen items-center justify-center">
      <form
        class="flex w-[648px] flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 p-6 cursor-pointer"
        enctype="multipart/form-data"
      >
        <input
          accept="audio/*,video/*"
          class="opacity-0 absolute cursor-pointer"
          id="file"
          name="file"
          type="file"
          onChange={onChange}
        />
        <label
          class="flex flex-col items-center justify-center w-full h-full cursor-pointer"
          for="file"
        >
          <UploadIcon class="text-gray-400 h-8 w-8" />
          <p class="mt-4 text-lg font-semibold cursor-pointer">
            导入音频或视频
          </p>
          <p class="text-gray-500">点击浏览，或拖放文件到此处</p>
        </label>
      </form>
    </div>
  )
}

function UploadIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
      <polyline points="17 8 12 3 7 8" />
      <line x1="12" x2="12" y1="3" y2="15" />
    </svg>
  )
}
