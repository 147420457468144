import { createEffect, JSX, onCleanup, onMount } from "solid-js"
import { createSignal } from "solid-js"
import { Button } from "./ui/button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select"

export function createVideoSignal() {
  const [previewPlayRate, setPreviewPlayRate] = createSignal(1)
  const [isPlaying, setIsPlaying] = createSignal(false)
  const [currentPlayingTime, setCurrentPlayingTime] = createSignal(0)

  return {
    previewPlayRate,
    setPreviewPlayRate,
    isPlaying,
    setIsPlaying,
    currentPlayingTime,
    setCurrentPlayingTime,
  }
}

export default function Video(props: {
  control: ReturnType<typeof createVideoSignal>
  source: string
  onTimeUpdate?: JSX.EventHandlerUnion<HTMLVideoElement, Event>
}): JSX.Element {
  let {
    previewPlayRate,
    setPreviewPlayRate,
    isPlaying,
    setIsPlaying,
    currentPlayingTime,
    setCurrentPlayingTime,
  } = props.control

  createEffect(() => {
    if (isPlaying()) {
      videoRef.play()
      // timer = setInterval(() => {
      //   setCurrentPlayingTime(videoRef.currentTime)
      // }, 10)
    } else {
      videoRef.pause()
      // clearInterval(timer)
    }
  })

  createEffect(() => {
    videoRef.playbackRate = previewPlayRate()
  })

  createEffect(() => {
    if (videoRef.currentTime != currentPlayingTime())
      videoRef.currentTime = currentPlayingTime()
  })

  let videoRef!: HTMLVideoElement

  let timer
  onMount(() => {
    setCurrentPlayingTime(videoRef.currentTime)
    timer = setInterval(() => {
      setCurrentPlayingTime(videoRef.currentTime)
    }, 5)
  })
  onCleanup(() => {
    clearInterval(timer)
  })

  return (
    <div>
      <div class="flex h-[calc(100vh-144px)] items-center justify-center bg-gray-900 text-white">
        <video
          class="h-[calc(100vh-144px)] bg-gray-900"
          ref={videoRef}
          autoplay={false}
          onPlay={() => setIsPlaying(true)}
          // onPause={() => setIsPlaying(false)}
          src={props.source}
        ></video>
      </div>
      <div class="flex items-center justify-between bg-black p-4">
        <div>
          <Button
            class="text-white"
            variant="outline"
            onClick={() => {
              setIsPlaying(!isPlaying())
            }}
          >
            {isPlaying() ? "Pause" : "Play"}
          </Button>
          <span class="ml-2 text-xs text-white">
            {currentPlayingTime() ? currentPlayingTime() : ""}
          </span>
        </div>
        {/* <Progress class="w-full bg-white h-1 rounded" value={50} /> */}
        <Select<{ value: number; label: string }>
          class="text-white"
          value={{
            value: previewPlayRate(),
            label: `${previewPlayRate()}x`,
          }}
          onChange={(item) => {
            setPreviewPlayRate(item.value)
            // videoRef!.playbackRate = item.value
          }}
          optionValue="value"
          optionTextValue="label"
          options={[
            { value: 0.25, label: "0.25x" },
            { value: 0.5, label: "0.5x" },
            { value: 0.75, label: "0.75x" },
            { value: 1, label: "1x" },
            { value: 2, label: "2x" },
            { value: 3, label: "3x" },
            { value: 4, label: "4x" },
            { value: 5, label: "5x" },
          ]}
          placeholder="1X"
          itemComponent={(props) => {
            return (
              <SelectItem item={props.item}>
                {props.item.rawValue.label}
              </SelectItem>
            )
          }}
        >
          <SelectTrigger aria-label="playbackRate" class="w-[180px]">
            <SelectValue<{ value: number; label: string }>>
              {(state) => {
                return state.selectedOption().label
              }}
            </SelectValue>
          </SelectTrigger>
          <SelectContent />
        </Select>
      </div>
    </div>
  )
}
